import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const A: React.FC<{ href: string }> = ({ href, children }) => (
  <a href={href} className='text-primary'>
    {children}
  </a>
)

const TopicHeading: React.FC<{ id?: string }> = ({ children, id = '' }) => (
  <h3 className='text-2xl font-bold'>
    <a id={id}></a>
    {children}
  </h3>
)

const P: React.FC<{ noBottomMargin?: boolean; endOfSection?: boolean }> = ({
  noBottomMargin,
  endOfSection,
  children,
}) => (
  <p className={endOfSection ? 'mb-17' : !noBottomMargin ? 'mb-4' : ''}>
    {children}
  </p>
)

const UL: React.FC<{ endOfSection?: boolean }> = ({
  endOfSection,
  children,
}) => (
  <ul className={`hyphened-ul ${endOfSection ? ' mb-20' : 'mb-4'}`}>
    {children}
  </ul>
)
const PrivacyPolicy: React.FC = () => (
  <Layout>
    <SEO title='Privacy Policy' />
    <div className='py-8 md:mx-24 md:max-w-148 leading-loose'>
      <h1 className='text-4xl font-bold mb-8'>Privacy Policy </h1>
      <P endOfSection={true}>
        Startup Growth Advisors IKE, a company registered in Greece, on behalf
        of Venture Friends 400W Management B.V., a company registered in The
        Netherlands, acting under its capacity as data controller is committed
        to safeguarding your privacy and protecting your personal data in
        compliance with applicable data protection laws, especially the General
        Data Protection Regulation EU 2016/679 (GDPR). Contact us at
        info@venturefriends.vc if you have any questions or concerns regarding
        the use of your Personal Data and we will gladly assist you. By using
        this website or/and our services, you accept the Processing of your
        Personal Data as described in this Privacy Policy.
      </P>
      <h2 className='text-2xl font-bold mb-4'>Table of Contents</h2>
      <ul className='text-primary mb-6'>
        <li>
          <A href='#definitions-used-in-this-policy'>
            1. Definitions used in this Policy
          </A>
        </li>
        <li>
          <A href='#data-protection-principles-we-follow'>
            2. Data protection principles we follow
          </A>
        </li>
        <li>
          <A href='#what-rights-do-you-have-regarding-your-personal-data'>
            3. What rights do you have regarding your Personal Data
          </A>
        </li>
        <li>
          <A href='#what-personal-data-we-gather-about-you'>
            4. What Personal Data we gather about you
          </A>
        </li>
        <li>
          <A href='#how-we-use-your-personal-data'>
            5. How we use your Personal Data
          </A>
        </li>
        <li>
          <A href='#how-we-secure-your-data'>6. How we secure your data</A>
        </li>
        <li>
          <A href='#information-about-cookies'>7. Information about cookies</A>
        </li>
        <li>
          <A href='#data-protection-authority'>8. Data Protection Authority</A>
        </li>
        <li>
          <A href='#contact-information'>9. Contact Information</A>
        </li>
      </ul>
      <TopicHeading id='definitions-used-in-this-policy'>
        Definitions
      </TopicHeading>
      <P>
        Personal Data – any information relating to an identified or
        identifiable natural person.
      </P>
      <P>
        Processing – any operation or set of operations which is performed on
        Personal Data or on sets of Personal Data.
      </P>
      <P>
        Data subject – a natural person whose Personal Data is being Processed.
      </P>
      Child – a natural person under 16 years of age.
      <P endOfSection={true}>
        We/us/our (either capitalized or not) – Startup Growth Advisors IKE, a
        company registered in Greece, on behalf of Venture Friends 400W
        Management B.V. a company registered in The Netherlands.
      </P>
      <TopicHeading id='data-protection-principles-we-follow'>
        Data Protection Principles
      </TopicHeading>
      <P>We promise to follow the following data protection principles:</P>
      <UL endOfSection={true}>
        <li>
          Processing is lawful, fair, transparent. Our Processing activities
          have lawful grounds. We always consider your rights before Processing
          your Personal Data. We will provide you with information regarding
          such Processing upon request.
        </li>
        <li>
          Processing is limited to its purpose. Our Processing activities fit
          the purpose for which Personal Data was gathered.
        </li>
        <li>
          Processing takes place with minimal data. We only gather and Process
          the minimal amount of Personal Data required for any purpose.
        </li>
        <li>
          Processing is limited with a time period. We will not store your
          personal data for longer than necessary.
        </li>
        <li>We will do our best to ensure the accuracy of data.</li>
        <li>
          We will do our best to ensure the integrity and confidentiality of
          data.
        </li>
        <li>
          We are responsible for and we are able to demonstrate our compliance
          with the GDPR at any time (accountability principle).
        </li>
      </UL>
      <TopicHeading id='what-rights-do-you-have-regarding-your-personal-data'>
        Data Subject’s rights
      </TopicHeading>
      <P>
        In all the cases in which we collect your Personal Data, the Data
        Subject has the following rights and, in most cases, the Data Subject
        can exercise them free of charge:
      </P>
      <P noBottomMargin={true}>
        1. Right to information – meaning you have to right to know whether your
        Personal Data is being processed; what data is gathered, from where it
        is obtained and why and by whom it is processed.{' '}
      </P>
      <P noBottomMargin={true}>
        2. Right to access – meaning you have the right to access the data
        collected from/about you. This includes your right to request and obtain
        a copy of your Personal Data gathered.{' '}
      </P>
      <P noBottomMargin={true}>
        3. Right to rectification – meaning you have the right to request
        rectification of your Personal Data that is inaccurate or incomplete.{' '}
      </P>
      <P noBottomMargin={true}>
        4. Right to erasure – meaning in certain circumstances you can request
        for your Personal Data to be erased from our records.{' '}
      </P>
      <P noBottomMargin={true}>
        5. Right to restrict processing – meaning where certain conditions
        apply, you have the right to restrict the Processing of your Personal
        Data.{' '}
      </P>
      <P noBottomMargin={true}>
        6. Right to object to processing – meaning in certain cases you have the
        right to object to Processing of your Personal Data, for example in the
        case of direct marketing.{' '}
      </P>
      <P noBottomMargin={true}>
        7. Right to object to automated Processing – meaning you have the right
        to object to automated Processing, including profiling; and not to be
        subject to a decision based solely on automated Processing, whenever
        there is an outcome of the profiling that produces legal effects
        concerning or significantly affecting you.{' '}
      </P>
      <P noBottomMargin={true}>
        8. Right to data portability – you have the right to obtain your
        Personal Data in a structured, commonly used and machine-readable format
        or if it is feasible, and you have the right to request that we transmit
        directly your Personal data to another Controller. Please note that this
        right applies only to Personal Data which you have provided directly to
        us.{' '}
      </P>
      <P noBottomMargin={true}>
        9. Right to lodge a complaint with the competent supervisory authority –
        in the event that we refuse your request under the Rights of Access, we
        will provide you with a reason as to why. If you are not satisfied with
        the way your request has been handled, please contact us.{' '}
      </P>
      <P endOfSection={true}>
        10. Right to withdraw your consent – you have the right to withdraw any
        given consent for Processing of your Personal Data at any time. The
        withdrawal of consent will not affect the lawfulness of processing based
        on consent before this withdrawal.
      </P>
      <TopicHeading id='what-personal-data-we-gather-about-you'>
        Data we gather
      </TopicHeading>
      <P>
        Information you have provided us with This might be your e-mail address,
        full name or other information you submit in our contact forms – mainly
        information that is necessary for delivering you a service or to enhance
        your customer experience with us. We save the information you provide to
        us. We may be provided from time to time with CVs of job candidates for
        openings at VentureFriends or any of our portfolio companies.
      </P>
      <P>
        Information automatically collected about you This includes information
        that is automatically stored by cookies and other session tools. For
        example, your IP address, your navigation path within the website etc.
        This information is used to improve your customer experience. When you
        use our services or look at the contents of our website, your activities
        may be logged.
      </P>
      <P>
        Information from our partners We gather information from our trusted
        partners with confirmation that they have legal grounds to share that
        information with us. This is either information you have provided them
        directly with or that they have gathered about you on other legal
        grounds.
      </P>
      <P endOfSection={true}>
        Publicly available information We might gather information about you
        that is publicly available.
      </P>
      <TopicHeading id='how-we-use-your-personal-data'>
        How we use your Personal Data
      </TopicHeading>
      <P>We use your Personal Data in order to:</P>
      <UL>
        <li>to identify you;</li>
        <li>to communicate with you;</li>
      </UL>
      <P>
        On the ground of legitimate interest (including a legitimate interest in
        performing our services, conducting our business, internal
        administration functions, defending legal claims etc.), we Process your
        Personal Data for the following purposes:
      </P>
      <UL>
        <li>
          to administer and analyse our visitors base (purchasing behaviour and
          history);
        </li>
      </UL>
      <P>
        With your consent we Process your Personal Data for the following
        purposes:
      </P>
      <UL>
        <li>to contanct you or send you newsletters;</li>
        <li>
          for other purposes we have asked your consent for (e.g. in order to
          send you relevant marketing communications that we believe may be of
          interest to you);
        </li>
      </UL>
      <P>
        We Process your Personal Data in order to fulfill obligation rising from
        the law and/or use your Personal Data for options provided by law. We
        reserve the right to anonymise Personal Data gathered and to use any
        such data (e.g. for statistical purposes). We will use data outside the
        scope of this Policy only when it is anonymised. We save your billing
        information and other information gathered about you for as long as
        needed for accounting purposes or other obligations deriving from law.
      </P>
      <P>
        We might process your Personal Data for additional purposes that are not
        mentioned here, but are compatible with the original purpose for which
        the data was gathered. To do this, we will ensure that:
      </P>
      <UL>
        <li>
          the link between purposes, context and nature of Personal Data is
          suitable for further Processing;
        </li>
        <li>the further Processing would not harm your interests and</li>
        <li>the further Processing would not harm your interests and</li>
      </UL>
      <P endOfSection={true}>
        We will inform you of any further Processing and purposes.
      </P>
      <TopicHeading>Recipients of your Personal Data</TopicHeading>
      <P>
        We do not allow Personal Data collected about you to be sold, traded,
        disclosed to or viewed by any third party. We may, however, disclose
        your Personal Data in limited circumstances, including:
      </P>
      <UL endOfSection={true}>
        <li>
          To service providers, third party agents and contractors who perform
          services on our behalf based on our instructions. We always conclude a
          data processing agreement with such third parties to ensure the
          appropriate level of security and confidentiality of your Personal
          Data. Examples of these are service providers that help us operate our
          Website, accounting services providers, vendors, professional advisors
          etc.;
        </li>
        <li>To our business partners;</li>
        <li>
          To other third parties, after obtaining your consent to disclose
          certain information;
        </li>
        <li>
          To law enforcement authorities, government officials, regulatory
          agencies or other parties, when we are required to do so by applicable
          law, regulation or legal process in connection, for instance, with an
          investigation conducted by judicial authorities regarding suspected
          and/or actual illegal activity or with a court order served on us, or
          when such disclosure is necessary upon our legitimate interests.
        </li>
      </UL>
      <TopicHeading>Transfers of Personal Data outside EU/EEA</TopicHeading>
      <P endOfSection={true}>
        We generally process your personal data within the EU/EEA. Nonetheless,
        as the case may arise, we may have to transfer your personal data
        outside EU/EEA, for example to a service provider located outside EU/EEA
        as necessary in order to organize an exhibition. However, the level of
        personal data protection in countries outside the EU/EEA may be lower
        than the respective provided within the EU/EEA. Where this is the case,
        we will implement appropriate safeguards in accordance with applicable
        data protection laws, in order to conduct such data transfer and ensure
        that your personal information remains protected and secure, such as
        Adequacy Decisions and Standard Contractual Clauses adopted by the
        European Commission.
      </P>
      <TopicHeading>Retention Period of your Personal Data</TopicHeading>
      <P endOfSection={true}>
        Our general approach is to retain your personal data only for as long as
        necessary to fulfill the purposes for which it was collected, as
        outlined in this Privacy Policy, and in any case, as required or
        permitted under applicable law and the corresponding statute of
        limitations. The criteria used to determine our retention periods
        include: (i) the time period we have an ongoing relationship with you;
        (ii) whether there is a legal retention obligation to which we are
        subject, such as legal, tax or accounting requirements; or (iii) whether
        retention is advisable in light of our legitimate interest to have an
        accurate record of your dealings with us in the event of any complaint
        or challenge, litigation or regulatory investigation.
      </P>
      <TopicHeading id='how-we-secure-your-data'>
        How we secure your data
      </TopicHeading>
      <P>
        We do our best to keep your Personal Data safe. We implement appropriate
        technical and organization measures including safe protocols for
        communication and transferring data (such as HTTPS), to protect
        adequately your Personal Data, in particular against accidental or
        unlawful destruction, loss, alteration, unauthorized disclosure or
        access. We use anonymization and pseudonymization where suitable. We
        monitor our systems for possible vulnerabilities and attacks.
      </P>
      <P>
        Even though we try our best we cannot guarantee the security of
        information since no personal data transmitted over the Internet is
        guaranteed to be 100% secure. However, we promise to notify suitable
        authorities of data breaches. We will also notify you if there is a
        threat to your rights or interests. We will do everything we reasonably
        can to prevent security breaches and to assist authorities should any
        breaches occur.
      </P>
      <P endOfSection={true}>
        If you have an account with us, note that you have to keep your username
        and password secret.
      </P>
      <TopicHeading>Children</TopicHeading>
      <P endOfSection={true}>
        We do not intend to collect or knowingly process information from
        children. We do not target children with our services.
      </P>
      <TopicHeading>Links to Other Websites</TopicHeading>
      <P endOfSection={true}>
        As a resource of information and convenience to our on-line visitors,
        our website may provide links to other websites owned or operated by
        third parties (e.g. Facebook and Twitter). Please be aware that we do
        not control other websites, nor have any liability for their content,
        security safeguards or privacy practices and that, in any case, the
        present Privacy Policy does not apply to these websites. Therefore, we
        strongly encourage you to get informed on the privacy policy of any
        linked website you visit.
      </P>
      <TopicHeading id='information-about-cookies'>
        Cookies and other technologies we use
      </TopicHeading>
      <P>
        We use cookies and/or similar technologies to analyse customer
        behaviour, administer the website, track users’ movements, and to
        collect information about users. This is done in order to personalise
        and enhance your experience with us.
      </P>
      <P>
        A cookie is a tiny text file stored on your computer. Cookies store
        information that is used to help make sites work. Only we can access the
        cookies created by our website. You can control your cookies at the
        browser level. Choosing to disable cookies may hinder your use of
        certain functions.
      </P>
      <P>We use cookies for the following purposes:</P>
      <UL>
        <li>
          Necessary cookies – these cookies are required for you to be able to
          use some important features on our website, such as logging in. These
          cookies don’t collect any personal information.
        </li>
        <li>
          Functionality cookies – these cookies provide functionality that makes
          using our service more convenient and makes providing more
          personalised features possible. For example, they might remember your
          name and e-mail in comment forms so you don’t have to re-enter this
          information next time when commenting.
        </li>
        <li>
          Analytics cookies – these cookies are used to track the use and
          performance of our website and services
        </li>
        <li>
          Advertising cookies – these cookies are used to deliver advertisements
          that are relevant to you and to your interests. In addition, they are
          used to limit the number of times you see an advertisement. They are
          usually placed to the website by advertising networks with the website
          operator’s permission. These cookies remember that you have visited a
          website and this information is shared with other organisations such
          as advertisers. Often targeting or advertising cookies will be linked
          to site functionality provided by the other organisation.
        </li>
      </UL>
      <P>
        You can remove cookies stored in your computer via your browser
        settings. Alternatively, you can control some 3rd party cookies by using
        a privacy enhancement platform such as{' '}
        <A href='https://optout.aboutads.info'>optout.aboutads.info</A> or
        <A href='https://youronlinechoices.com'> youronlinechoices.com</A>. For
        more information about cookies, visit
        <A href='https://allaboutcookies.org'> allaboutcookies.org</A>.
      </P>
      <P endOfSection={true}>
        We use Google Analytics to measure traffic on our website. Google has
        their own Privacy Policy which you can review <A href=''>here</A>. If
        you’d like to opt out of tracking by{' '}
        <A href=''>Google Analytics, visit the Google Analytics opt-out page</A>
        .
      </P>
      <TopicHeading id='data-protection-authority'>
        Data Protection Authority
      </TopicHeading>
      <h4 className='font-bold'>Contact Information</h4>
      <address className='not-italic'>
        <P noBottomMargin={true}>Hellenic Data Protection Authority </P>
        <P noBottomMargin={true}>
          <A href='http://www.dpa.gr/'> http://www.dpa.gr/ </A>
        </P>
        <P noBottomMargin={true}>1-3 Kifissias</P>
        <P noBottomMargin={true}>Avenue, PC 11523, Athens </P>
        <P noBottomMargin={true}>
          <A href='mailto:contact@dpa.gr'>contact@dpa.gr </A>
        </P>
        <P noBottomMargin={true} endOfSection={true}>
          tel: +30 210 6475 600
        </P>
      </address>
      <TopicHeading id='contact-information'>Contact Information</TopicHeading>
      <P endOfSection={true}>
        You may contact us for any matter or request concerning the handling of
        your private data at:{' '}
        <A href='mailto:info@venturefriends.vc'> info@venturefriends.vc</A>
      </P>
      <TopicHeading>Changes to this Privacy Policy</TopicHeading>
      <P endOfSection={true}>
        We may periodically update the present Privacy Policy, in order, for
        instance, to comply with new legal requirements. Any changes to this
        Privacy Policy will be posted on our website in order to keep you
        informed, while we may personally notify you in case of any material
        changes, including but not limited to, by sending you an e-mail.
        Accordingly, we encourage you to consult this website frequently, so
        that you are aware of our latest Privacy Notice and/or any updates. Last
        modification was made in April 2019.
      </P>
    </div>
  </Layout>
)

export default PrivacyPolicy
